<template>
    <div v-if="!loading">
        <div class="card mb-5 mb-xxl-8 py-0">
            <div class="card-body pt-12 pb-0">
                <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
                    <div class="me-7 mb-4">
                        <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img :src="profileData.icon_path" alt="image" />
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-items-center mb-2">
                                    <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                        {{ profileData.getFullName }}
                                    </a>
                                </div>

                                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    <p class="d-flex align-items-center text-gray-400 me-5 mb-2">
                                        <img src="/media/buying/icons/business.svg" class="me-2" alt="">
                                        {{ profileData.bank_name }}
                                    </p>
                                    <a class="d-flex align-items-center text-gray-400 me-5 mb-2">
                                        <img src="/media/buying/icons/user.svg" class="me-2" alt="">
                                        {{ profileData.job_title }}
                                    </a>
                                    <a class="d-flex align-items-center text-gray-400 me-5 mb-2">
                                        <img src="/media/buying/icons/email.svg" class="me-2" alt="">
                                        {{ profileData.email }}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap flex-stack">
                            <div class="d-flex flex-column flex-grow-1 pe-8">
                                <div class="d-flex flex-wrap user-counter">
                                    <template v-for="(item, index) in counter">
                                        <div class="border border-gray-300 rounded min-w-175px py-3 px-4 me-6 mb-3">
                                            <div class="d-flex flex-column align-items-center">
                                                <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                                                <div class="fs-1 fw-boldest">{{ item.value }}</div>
                                            </div>
                                        </div>
                                        <p class="border-start border-gray-300 me-6" v-if="index === 0"></p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-5 g-xxl-8 mx-0">
            <div class="card py-2" id="kt_profile_details_view">
                <div class="card-header cursor-pointer px-0">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("User Details") }}</h3>
                    </div>
                    <div v-if="!editProfile && hasAccessToEdit">
                        <p @click="editProfile = true" class="text-primary edit my-3 d-block mb-5">
                            <img height="12" class="me-3" src="/media/buying/icons/edit.svg" alt="">
                            {{ $t("Edit profile") }}
                        </p>
                    </div>
                </div>
                <BankTeamProfileDetails
                    v-if="!editProfile"
                    :profileDetails="profileData"
                />
                <BankSingleUserDetailsForm
                    v-else
                    ref="profileDetailsFormRef"
                    :userData="profileData"
                    :countriesAndProductAreasData="profileData.countriesAndProductAreasData"
                    :permissions-groups="permissionsGroups"
                    @handle-discard="onDiscard"
                    @on-user-update="handleUserUpdate"
                />
            </div>

            <ActiveProductAreas :data="profileData.active_product_areas" :show-info-text="false" />
        </div>
    </div>
    <div v-else class="mx-0">
        <CustomLoader />
    </div>
</template>
<script>
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import BankCustomers from "@/buying-teams/shared-components/settings/BankCustomers";
import { BankTeamUserModel } from "@/store/models/bank/BankTeamUserModel";
import BankTeamProfileDetails from "@/buying-teams/pages/bank/teams/components/BankTeamProfileDetails";
import ActiveProductAreas from "@/buying-teams/pages/bank/settings/bank/components/ActiveProductAreas";
import BankProfileDetailsForm from "@/buying-teams/pages/bank/settings/user/BankProfileDetailsForm";
import BankSingleUserDetailsForm from "@/buying-teams/pages/bank/settings/user/BankSingleUserDetailsForm.vue";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "SingleUser",

    components: {
        BankSingleUserDetailsForm,
        BankProfileDetailsForm,
        ActiveProductAreas,
        BankTeamProfileDetails,
        BankCustomers,
        CustomLoader
    },

    data() {
        return {
            profileData: null,
            editProfile: false,
            permissionsGroups: [],
            loading: true
        };
    },

    computed: {
        user() {
            return store.getters.currentUser;
        },
        bank() {
            return store.getters.bank;
        },
        counter() {
            return [
                {
                    label: "Average Score",
                    key: "score",
                    value: this.profileData ? this.profileData.score : 0
                },
                {
                    label: "Followed Countries",
                    key: "replies",
                    value: this.profileData ? this.profileData.followed_countries.length : 0
                },
                {
                    label: "Followed Product Areas",
                    key: "intents_count",
                    value: this.profileData ? this.profileData.followed_product_areas.length : 0
                }
            ];
        },
        hasAccessToEdit() {
            return this.user.is_admin && (
                this.profileData.role === UserRoleEnum.USER && (this.user.can(BankUserPermissionTypeEnum.EDIT_USER) || this.user.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) ||
                this.profileData.role === UserRoleEnum.ADMIN && (this.user.can(BankUserPermissionTypeEnum.EDIT_ADMIN) || this.user.can(BankUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS))
            )
        }
    },
    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });
        this.loading = true;
        this.getUserData().finally(() => {
            this.loading = false;
        });
    },
    methods: {
        getUserData() {
            return store.dispatch("fetchBankUserData", this.$route.params.id).then( async (res) => {
                this.profileData = new BankTeamUserModel(res);

                if (this.profileData.role === UserRoleEnum.USER) {
                    this.permissionsGroups = await store.dispatch('getPermissionsGroups');
                }

                const isEditPage = !!this.$route.query.edit;
                if (this.hasAccessToEdit && isEditPage) {
                    this.editProfile = true;
                }
            });
        },
        confirmProfileUpdate(userData) {
            this.profileData.selfUpdate(userData);
        },
        onDiscard() {
            this.editProfile = false;
        },
        handleUserUpdate() {
            this.editProfile = false;
            this.getUserData();
        }
    }
};
</script>
<style lang="scss">
.selectProductArea {
    .el-input__inner {
        background: #F6F6F6;
        border: 1px solid #435BF4;
        border-radius: 26px;
        height: 45px;
        padding: 0 20px;
    }
}

.symbol.symbol-lg-160px.symbol-fixed > img {
    object-fit: cover;
    object-position: top;
}
</style>
