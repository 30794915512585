<template>
    <div class="collapse show companyDetailsForm">
        <el-form
            v-show="!isLoading"
            ref="profileFormRef"
            class="w-xl-75"
            :model="profileDetails"
            :rules="profileDetailsRules"
            label-width="300px">

            <el-form-item class="fullName" :label="$t('Picture')">
                <FileUploadWithCropper
                    :imageUrl="profileDetails.icon_path === Constants.USER_TMP_LOGO ? null : profileDetails.icon_path"
                    :loading="fileUploadLoading"
                    :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
                    :acceptedSize="9216"
                    :blankImageType="'avatar'"
                    :blank-image-type="BlankImageTypesEnum.AVATAR"
                    :popup-title="$t('Crop Image')"
                    :popup-description="$t('Confirm your image upload by properly adjusting your Profile Picture')"
                    @getImages="handleUpload"
                    @getErrors="errors => {fileUploadErrors = errors}"
                />
                <div class="el-form-item__error">{{fileUploadErrors.join(', ')}}</div>
            </el-form-item>

            <el-form-item prop="first_name" class="fullName" :label="$t('Full Name')">
                <el-input
                    v-model="profileDetails.first_name"
                    :placeholder="$t('Input your First Name')" />
                <el-input
                    v-model="profileDetails.last_name"
                    :placeholder="$t('Input your Lat Name')" />
            </el-form-item>

            <el-form-item prop="job_title" :label="$t('Job Title')" >
                <el-input v-model="profileDetails.job_title"></el-input>
            </el-form-item>

            <el-form-item :label="$t('Country of Residence')">
                <el-select
                    v-model="profileDetails.country"
                    filterable
                    :placeholder="$t('Your Country of residence')">
                    <el-option
                        v-for="(item,index) in countries"
                        :key="index"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <!--      <el-form-item :label="$t('Preferred Language')">-->
            <!--        <el-select-->
            <!--            v-model="profileDetails.language"-->
            <!--            class="preferred-language"-->
            <!--            popper-class="select-language-dropdown">-->
            <!--          <template #prefix>-->
            <!--            <img :src="profileDetailsLanguage.icon_path" width="20" height="20" alt="">-->
            <!--            <span>{{ profileDetailsLanguage.title }}</span>-->
            <!--          </template>-->
            <!--          <el-option-->
            <!--              v-for="(item, value) in supportedLanguages"-->
            <!--              :key="value"-->
            <!--              :value="value"-->
            <!--          >-->
            <!--            <div class="select-language__item">-->
            <!--              <div class="select-language__item&#45;&#45;icon">-->
            <!--                <img :src="item.icon_path" alt="">-->
            <!--              </div>-->
            <!--              <p>{{ item.title }}</p>-->
            <!--              <span>{{ item.subTitle }}</span>-->
            <!--            </div>-->
            <!--          </el-option>-->
            <!--        </el-select>-->
            <!--      </el-form-item>-->
        </el-form>

        <CustomLoader v-show="isLoading"/>

        <hr />

        <div class="d-flex mt-10 justify-content-end align-items-center">
            <el-button @click="resetForm">{{ $t("Discard") }}</el-button>
            <button
                :data-kt-indicator="buttonIndicator"
                :disabled="buttonIndicator === 'on' || fileUploadLoading || isLoading"
                class="main-btn btn"
                @click="$emit('handle-save')">
                <span class="indicator-label"> {{ $t("Save Changes") }}</span>

                <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span
              class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
            </button>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
import {Constants, SUPPORTED_LANGUAGES} from "@/core/config/constants";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {BlankImageTypesEnum} from "@/store/enums/BlankImageTypesEnum";

export default {
    name: "BankProfileDetailsForm",

    components: {
        FileUploadWithCropper,
        CustomLoader,
    },

    props: {
        userData: Object,
        buttonIndicator: String,
        countriesAndProductAreasData: Object,
        showFollowedFormSection: {
            type: Boolean,
            default: true,
        }
    },

    emits: ['handle-save', 'handle-discard'],

    data() {
        return {
            BlankImageTypesEnum,
            supportedLanguages: SUPPORTED_LANGUAGES,
            Constants,
            fileUploadErrors: [],
            fileUploadLoading: false,
            isLoading: false,
            profileDetails: {
                icon_path: "",
                first_name: "",
                last_name: "",
                country: "",
                job_title: "",
                // language: "",
            },
            profileDetailsRules: {
                first_name: [
                    { required: true, message: this.$t("Please input First Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                last_name: [
                    { required: true, message: this.$t("Please input Last Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                country: [
                    { required: true, message: this.$t("Please input Country Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                job_title: [
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ]
            }
        };
    },
    computed: {
        countries() {
            return store.getters.allCountries;
        },
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : '/media/buying/icons/languages/globe-earth.svg',
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t('Select'),
            }
        }
    },
    async mounted() {
        this.fillForm({...this.userData})
    },
    methods: {
        handleUpload(files) {
            if (!files.length) {
                this.profileDetails.icon_path = null;
                return
            }

            this.fileUploadLoading = true;
            store.dispatch('imageUpload', files).then(responses => {
                this.profileDetails.icon_path = responses.key
                this.fileUploadLoading = false;
            }).catch(() => {
                this.fileUploadLoading = false;
            })
        },
        fillForm(userData) {
            this.profileDetails = {
                icon_path: userData.icon_path,
                first_name: userData.first_name,
                last_name: userData.last_name,
                country: userData.country,
                job_title: userData.job_title,
                // language: userData.language,
            };
        },
        resetForm() {
            this.fillForm({...this.userData})
            this.$emit('handle-discard');
        }

    }
};
</script>
<style lang="scss">
.companyDetailsForm {
    .fullName {
        .el-form-item__content {
            display: flex;
            gap: 20px;
        }
    }
    .multiple-select-user-settings {
        margin-bottom: 30px;
        .select-trigger {
            .el-select__tags {
                min-height: 28px;
            }
        }
    }
}
</style>
