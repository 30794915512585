<template>
    <div class="card-body p-9 px-0 profile-details" v-if="profileDetails">
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Full Name") }}</label>
            <div class="col-lg-8">
                <span class="profile-details__text">{{ profileDetails.getFullName }}</span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Registered Bank") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text">{{ profileDetails.bank_name }}</span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Followed Countries") }}</label>
            <div class="col-lg-8 d-flex flex-wrap align-items-center">
                <span
                    class="profile-details__text"
                    v-if="profileDetails.followed_countries.length"
                    v-for="(item, index) in profileDetails.followed_countries"
                    :key="index"
                >
                    {{ item }}{{ index !== profileDetails.followed_countries.length - 1 ? ",&nbsp;" : "" }}
                </span>
                <span v-else class="profile-details__text">
                    {{ $t("No Data") }}
                </span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Followed Product Areas") }}</label>
            <div class="col-lg-8 d-flex flex-wrap align-items-center">
                <span
                    class="profile-details__text"
                    v-if="profileDetails.followed_product_areas.length"
                    v-for="(item, index) in profileDetails.followed_product_areas"
                    :key="index"
                >
                    {{ item }}{{ index !== profileDetails.followed_product_areas.length - 1 ? ",&nbsp;" : "" }}
                </span>
                <span v-else class="profile-details__text">
                    {{ $t("No Data") }}
                </span>
            </div>
        </div>
        <div class="row">
            <label class="col-lg-4 profile-details__label">{{ $t("Role") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text text-capitalize">{{ profileDetails.role }}</span>
            </div>
        </div>
        <div class="row mt-8" v-if="profileDetails.role === UserRoleEnum.USER">
            <label class="col-lg-4 profile-details__label">{{ $t("Permission Group") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text text-capitalize">
                    {{ profileDetails.getPermissionGroupName || $t('No Permission Group') }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export default {
    name: "BankTeamProfileDetails",

    props: ["profileDetails"],

    data() {
        return {
            UserRoleEnum
        }
    }
};
</script>
<style lang="scss" scoped>
.profile-details {
    &__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8B8B8B;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #040404;
    }
}
</style>
