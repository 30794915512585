<template>
    <div class="collapse show companyDetailsForm">
        <el-form
            v-show="!isLoading"
            ref="profileFormRef"
            :model="profileDetails"
            :rules="profileDetailsRules"
            label-width="300px">

            <div class="user-info-form" :class="{ 'has-no-access': !hasAccessToEditInfo }">
                <div class="w-xl-75">
                    <el-form-item class="fullName" :label="$t('Picture')">
                        <FileUploadWithCropper
                            :imageUrl="profileDetails.icon_path === Constants.USER_TMP_LOGO ? null : profileDetails.icon_path"
                            :loading="fileUploadLoading"
                            :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
                            :acceptedSize="9216"
                            :blankImageType="'avatar'"
                            :blank-image-type="BlankImageTypesEnum.AVATAR"
                            :popup-title="$t('Crop Image')"
                            :popup-description="$t('Confirm your image upload by properly adjusting your Profile Picture')"
                            :disabled="!hasAccessToEditInfo"
                            @getImages="handleUpload"
                            @getErrors="errors => {fileUploadErrors = errors}"
                        />
                        <div class="el-form-item__error">{{ fileUploadErrors.join(", ") }}</div>
                    </el-form-item>
                    <el-form-item prop="first_name" class="fullName" :label="$t('Full Name')">
                        <el-input
                            v-model="profileDetails.first_name"
                            :disabled="!hasAccessToEditInfo"
                            :placeholder="$t('Input your First Name')" />
                        <el-input
                            v-model="profileDetails.last_name"
                            :disabled="!hasAccessToEditInfo"
                            :placeholder="$t('Input your Lat Name')" />
                    </el-form-item>
                    <el-form-item prop="job_title" :label="$t('Job Title')">
                        <el-input v-model="profileDetails.job_title" :disabled="!hasAccessToEditInfo"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Country of Residence')">
                        <SelectBoxWithModal
                            v-model="profileDetails.country"
                            self-value
                            :is-disabled="!hasAccessToEditInfo"
                            :is-mandatory-select="false"
                            :options="countries"
                            :all-selected-text="$t('All Selected')"
                            :placeholder="$t('Select Countries')"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('Active Product Areas')">
                        <SelectBoxWithModal
                            v-model="profileDetails.followed_product_areas"
                            self-value
                            multiple
                            :is-disabled="!hasAccessToEditInfo"
                            :is-mandatory-select="false"
                            :options="countriesAndProductAreasData.allProductAreas"
                            :all-selected-text="$t('All Selected')"
                            :custom-selection-text="`${profileDetails.followed_product_areas.length} ${$t('Product Areas')}`"
                            :placeholder="$t('Select Product Areas')"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('Active Countries')">
                        <SelectBoxWithModal
                            v-model="profileDetails.followed_countries"
                            self-value
                            multiple
                            :is-disabled="!hasAccessToEditInfo"
                            :is-mandatory-select="false"
                            :options="countriesAndProductAreasData.allCountries"
                            :all-selected-text="$t('All Selected')"
                            :custom-selection-text="`${profileDetails.followed_countries.length} ${$t('Countries')}`"
                            :placeholder="$t('Select Countries')"
                        />
                    </el-form-item>
                    <!--      <el-form-item :label="$t('Preferred Language')">-->
                    <!--        <el-select-->
                    <!--            v-model="profileDetails.language"-->
                    <!--            class="preferred-language"-->
                    <!--            popper-class="select-language-dropdown">-->
                    <!--          <template #prefix>-->
                    <!--            <img :src="profileDetailsLanguage.icon_path" width="20" height="20" alt="">-->
                    <!--            <span>{{ profileDetailsLanguage.title }}</span>-->
                    <!--          </template>-->
                    <!--          <el-option-->
                    <!--              v-for="(item, value) in supportedLanguages"-->
                    <!--              :key="value"-->
                    <!--              :value="value"-->
                    <!--          >-->
                    <!--            <div class="select-language__item">-->
                    <!--              <div class="select-language__item&#45;&#45;icon">-->
                    <!--                <img :src="item.icon_path" alt="">-->
                    <!--              </div>-->
                    <!--              <p>{{ item.title }}</p>-->
                    <!--              <span>{{ item.subTitle }}</span>-->
                    <!--            </div>-->
                    <!--          </el-option>-->
                    <!--        </el-select>-->
                    <!--      </el-form-item>-->
                </div>
            </div>

            <div class="user-info-form" :class="{ 'has-no-access': !hasAccessToEditPermissions }">
                <hr />

                <div class="permission-group--title mb-9 mt-6">
                    {{ $t('Permissions') }}
                </div>

                <template v-if="userData.role === UserRoleEnum.USER">
                    <div class="w-xl-75">
                        <el-form-item :label="$t('Permission Group')">
                            <el-select
                                filterable
                                :placeholder="$t('No Permission Group')"
                                :disabled="!hasAccessToEditPermissions"
                                v-model="permissionsFormGroupId"
                                @change="handleChangePermissionGroup"
                            >
                                <el-option
                                    v-for="(item,index) in permissionsGroups"
                                    :key="index"
                                    :value="item.id"
                                    :label="item.name"
                                >
                                    {{ item.name }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <hr />
                </template>

                <div class="w-xl-75">
                    <div class="el-form-item mt-8">
                        <div class="el-form-item__label" style="width: 300px;">
                            {{ $t('Functions') }}
                            <el-tooltip
                                effect="dark"
                                placement="bottom-start"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                                <template #content>
                                    <div class="permission-tooltip">
                                        {{ $t('If all functions are disabled, user will be in a view-only mode.') }}
                                    </div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="el-form-item__content">
                            <div class="permission-group--input">
                                <div class="group-form-switch mb-4">
                                    <div class="group-form-switch-label switch-bold">
                                        {{ $t('All') }}
                                    </div>
                                    <el-switch v-model="allFunctions" :disabled="!hasAccessToEditPermissions" @change="handleChangedAll" />
                                </div>
                                <div class="group-form-divider"></div>

                                <div
                                    class="group-form-switch mb-6 mt-4"
                                    v-for="(item, key) in permissionsList"
                                >
                                    <div class="group-form-switch-label">
                                        {{ $t(item) }}
                                    </div>

                                    <label class="switch">
                                        <input
                                            v-model="permissionsForm.functional_permissions"
                                            @change="handleChangePermissions"
                                            type="checkbox"
                                            :disabled="!hasAccessToEditPermissions"
                                            :name="key"
                                            :value="key"
                                        >
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-xl-75" v-if="userData.role === UserRoleEnum.USER">
                    <div class="el-form-item mt-8">
                        <div class="el-form-item__label" style="width: 300px;">
                            {{ $t('Access') }}
                            <el-tooltip
                                effect="dark"
                                placement="bottom-start"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                                <template #content>
                                    <div class="permission-tooltip">
                                        {{ $t('Give access to specific product areas, countries or customers by selecting them in the boxes on the right. Selecting all will give access to future additions also.') }}
                                    </div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="el-form-item__content">
                            <div class="group-form-input mb-5">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Product Areas") }}</h4>
                                    <button
                                        v-if="bankProductAreasList.length && permissionsFormUserRole.product_areas.length !== bankProductAreasList.length"
                                        @click="resetToDefault('product_areas', bankProductAreasList)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <SelectBoxWithModal
                                    v-model="permissionsFormUserRole.product_areas"
                                    self-value
                                    multiple
                                    :options="bankProductAreasList"
                                    :all-selected-text="$t('All Selected')"
                                    :is-mandatory-select="false"
                                    :custom-selection-text="`${permissionsFormUserRole.product_areas.length} ${$t('Permitted')}`"
                                    :placeholder="$t('Select Product Areas')"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    :modal-title="$t('Product Areas')"
                                    :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Product Areas') })"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                            <div class="group-form-input mb-5">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Countries") }}</h4>
                                    <button
                                        v-if="bankCountriesList.length && permissionsFormUserRole.countries.length !== bankCountriesList.length"
                                        @click="resetToDefault('countries', bankCountriesList)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <SelectBoxWithModal
                                    v-model="permissionsFormUserRole.countries"
                                    self-value
                                    multiple
                                    :options="bankCountriesList"
                                    :all-selected-text="$t('All Selected')"
                                    :is-mandatory-select="false"
                                    :custom-selection-text="`${permissionsFormUserRole.countries.length} ${$t('Permitted')}`"
                                    :placeholder="$t('Select Countries')"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    :modal-title="$t('Countries')"
                                    :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Countries') })"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                            <div class="group-form-input">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Customers") }}</h4>
                                    <button
                                        v-if="bankCustomersList.length && permissionsFormUserRole.customers.length !== bankCustomersList.length"
                                        @click="resetToDefault('customers', bankCustomersListIds)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <CustomersBanksSelectBox
                                    v-model="permissionsFormUserRole.customers"
                                    multiple
                                    :options="bankCustomersList"
                                    :placeholder="$t('Select Customers')"
                                    :modal-title="$t('Customers')"
                                    :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Customers') })"
                                    :is-mandatory-select="false"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>

        <CustomLoader v-show="isLoading" />

        <div class="d-flex mt-10 justify-content-end align-items-center">
            <el-button @click="resetForm">{{ $t("Discard") }}</el-button>
            <button
                :data-kt-indicator="buttonIndicator"
                :disabled="buttonIndicator === 'on' || fileUploadLoading || isLoading"
                class="main-btn btn"
                @click="onSubmit">
                <span class="indicator-label"> {{ $t("Save Changes") }}</span>

                <span class="indicator-progress">
                    {{ $t("pleaseWait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
import { Constants, SUPPORTED_LANGUAGES } from "@/core/config/constants";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BlankImageTypesEnum } from "@/store/enums/BlankImageTypesEnum";
import CustomersBanksSelectBox from "@/buying-teams/shared-components/settings/CustomersBanksSelectBox.vue";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal.vue";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import NotificationService from "@/buying-teams/services/NotificationService";
import { Position } from "@element-plus/icons-vue";
import { filterAllowedCustomers } from "@/store/models/bank/permissions/BankPermissionGroup";

const usersPermissions = {
    [BankUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION]: 'Create feedback session',
    [BankUserPermissionTypeEnum.INVITE_CUSTOMERS]: 'Invite customers',
    [BankUserPermissionTypeEnum.COMMENT_REQUEST]: 'Comment on requests',
    [BankUserPermissionTypeEnum.COMMENT_IDEA]: 'Comment on ideas',
    [BankUserPermissionTypeEnum.OPEN_CLOSE_REQUEST_IDEA]: 'Open/close requests and ideas',
};

const adminsPermissions = {
    [BankUserPermissionTypeEnum.INVITE_USER]: 'Invite user',
    [BankUserPermissionTypeEnum.EDIT_USER]: 'Edit user',
    [BankUserPermissionTypeEnum.PROMOTE_DEMOTE_USER]: 'Promote/demote user',
    [BankUserPermissionTypeEnum.DELETE_USER]: 'Delete user',
    [BankUserPermissionTypeEnum.EDIT_BANK_SETTINGS]: 'Edit bank',
    [BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS]: 'Change user permissions and permission groups',
    [BankUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS]: 'Change admin permissions',
    [BankUserPermissionTypeEnum.INVITE_ADMIN]: 'Invite admin',
    [BankUserPermissionTypeEnum.EDIT_ADMIN]: 'Edit admin',
    [BankUserPermissionTypeEnum.DELETE_ADMIN]: 'Delete admin',
};

export default {
    name: "BankSingleUserDetailsForm",

    components: {
        Position,
        SelectBoxWithModal,
        CustomersBanksSelectBox,
        FileUploadWithCropper,
        CustomLoader
    },

    props: {
        userData: Object,
        countriesAndProductAreasData: Object,
        permissionsGroups: Array,
    },

    emits: ["handle-save", "handle-discard", "on-user-update"],

    data() {
        return {
            BlankImageTypesEnum,
            UserRoleEnum,
            BankUserPermissionTypeEnum,
            allFunctions: false,
            supportedLanguages: SUPPORTED_LANGUAGES,
            Constants,
            fileUploadErrors: [],
            fileUploadLoading: false,
            isLoading: false,
            profileDetails: {
                icon_path: "",
                first_name: "",
                last_name: "",
                country: "",
                job_title: "",
                followed_countries: [],
                followed_product_areas: [],
                // language: "",
            },
            permissionsForm: {
                functional_permissions: [],
            },
            permissionsFormGroupId: null,
            permissionsFormUserRole: {
                countries: [],
                product_areas: [],
                customers: [],
            },
            profileDetailsRules: {
                first_name: [
                    { required: true, message: this.$t("Please input First Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                last_name: [
                    { required: true, message: this.$t("Please input Last Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                country: [
                    { required: true, message: this.$t("Please input Country Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                job_title: [
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ]
            },
            buttonIndicator: "off",
        };
    },
    computed: {
        countries() {
            return store.getters.allCountries;
        },
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : "/media/buying/icons/languages/globe-earth.svg",
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t("Select")
            };
        },
        permissionsList() {
            return this.userData.role === UserRoleEnum.USER ? usersPermissions : adminsPermissions;
        },
        bankProductAreasList() {
            return store.getters.getBankProductAreas;
        },
        bankCustomersList() {
            return store.getters.getBankCustomersList;
        },
        bankCustomersListIds() {
            return this.bankCustomersList.map(c => c.id);
        },
        bankCountriesList() {
            return store.getters.getBankCountries;
        },
        currentUser() {
            return store.getters.currentUser
        },
        hasAccessToEditInfo() {
            return (this.userData.role === UserRoleEnum.USER && this.currentUser.can(BankUserPermissionTypeEnum.EDIT_USER) ||
                (this.userData.role === UserRoleEnum.ADMIN && this.currentUser.can(BankUserPermissionTypeEnum.EDIT_ADMIN)))
        },
        hasAccessToEditPermissions() {
            return (this.userData.role === UserRoleEnum.USER && this.currentUser.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS) ||
                (this.userData.role === UserRoleEnum.ADMIN && this.currentUser.can(BankUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS)))
        }
    },
    async mounted() {
        this.fillForm({ ...this.userData });
    },
    methods: {
        handleUpload(files) {
            if (!files.length) {
                this.profileDetails.icon_path = null;
                return;
            }

            this.fileUploadLoading = true;
            store.dispatch("imageUpload", files).then(responses => {
                this.profileDetails.icon_path = responses.key;
                this.fileUploadLoading = false;
            }).catch(() => {
                this.fileUploadLoading = false;
            });
        },
        fillForm(userData) {
            if (userData) {
                for (let data of Object.keys(this.profileDetails)) {
                    if (userData[data]) this.profileDetails[data] = userData[data];
                }
                this.fillPermissionsForm(userData);
            }
        },
        fillPermissionsForm(userData) {
            if (userData.role === UserRoleEnum.ADMIN) {
                this.permissionsForm.functional_permissions = userData.permissions.functionalPermissions.filter(p => !p.includes('pu'));
            } else if (userData.role === UserRoleEnum.USER) {
                if (userData.permissions.isGroup) {
                    this.permissionsFormGroupId = userData.permissions.id;
                }
                this.permissionsForm.functional_permissions = userData.permissions.functionalPermissions;
                this.permissionsFormUserRole.countries = userData.permissions.allowedCountries;
                this.permissionsFormUserRole.product_areas = userData.permissions.allowedProductAreas;
                this.permissionsFormUserRole.customers = filterAllowedCustomers(userData.permissions.blockedCustomers, this.bankCustomersListIds);
            }
        },
        resetForm() {
            this.fillForm({ ...this.userData });
            this.$emit("handle-discard");
        },
        handleChangedAll(value) {
            this.permissionsForm.functional_permissions = value ? Object.keys(this.permissionsList) : [];
            this.handleChangePermissions();
        },
        async onSubmit() {
            let payload = {
                id: this.$route.params.id,
            }

            if (this.hasAccessToEditPermissions) {
                if (this.permissionsFormGroupId) {
                    payload.permissions = {};
                    payload.permissions.group_id = this.permissionsFormGroupId
                } else {
                    payload.permissions = {
                        ...this.permissionsForm,
                        ...this.permissionsFormUserRole,
                    }
                    payload.permissions.customers = filterAllowedCustomers(this.permissionsFormUserRole.customers, this.bankCustomersListIds);
                }

                if (this.userData.role === UserRoleEnum.ADMIN) {
                    payload.permissions = {
                        functional_permissions: this.permissionsForm.functional_permissions
                    }
                }
            }

            if (this.hasAccessToEditInfo) {
                payload = { ...payload, user_data: { ...this.profileDetails } }
            }

            await this.$refs.profileFormRef.validate(async valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    await store.dispatch("updateBankUserProfileDetails", payload)
                        .then(async (res) => {
                            if (res) {
                                NotificationService.swalCustom({
                                    title: "Success",
                                    icon: "success"
                                });
                                this.$emit('on-user-update');
                            }
                        }).catch(err => {
                            console.log(err, "----err");
                        }).finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        },
        handleChangePermissions() {
            this.permissionsFormGroupId = null;
        },
        handleChangePermissionGroup() {
            const selectedGroup = this.permissionsGroups.find(p => p.id === this.permissionsFormGroupId);
            if (selectedGroup) {
                this.fillPermissionsForm({
                    ...this.userData,
                    permissions: selectedGroup
                })
            }
        },
        resetToDefault(key, allList) {
            this.permissionsFormUserRole[key] = [...allList];
            this.permissionsFormGroupId = null;
        }
    },
    watch: {
        'permissionsForm.functional_permissions': {
            deep: true,
            handler(newVal) {
                this.allFunctions = newVal.length === Object.keys(this.permissionsList).length;
            }
        },
        permissionsFormUserRole: {
            deep: true,
            handler(newVal) {
                if (this.userData.role === UserRoleEnum.USER) {
                    this.userData.updateUserCountriesAndProductAreas(newVal.countries, newVal.product_areas);
                    this.profileDetails.followed_countries =
                        this.profileDetails.followed_countries.filter(ct => newVal.countries.includes(ct));
                    this.profileDetails.followed_product_areas =
                        this.profileDetails.followed_product_areas.filter(pa => newVal.product_areas.includes(pa));
                }
            }
        }
    },
};
</script>
<style lang="scss">
.companyDetailsForm {
    .fullName {
        .el-form-item__content {
            display: flex;
            gap: 20px;
        }
    }

    .multiple-select-user-settings {
        margin-bottom: 30px;

        .select-trigger {
            .el-select__tags {
                min-height: 28px;
            }
        }
    }

    .permission-group {
        &--title {
            color: #242424;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        }
    }

    .group-form-divider {
        width: 100%;
        height: 1px;
        background: #EBEEF5;
    }

    .group-form-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .group-form-switch-label {
            color: #242424;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            &.switch-bold {
                font-weight: 700;
            }
        }
    }

    .group-form-input-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        h4 {
            color: #595959;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            margin-bottom: 0;
        }
        button {
            color: #435BF4;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            background: transparent;
            border: none;
            padding: 0;
            &:hover {
                opacity: .8;
            }
        }
    }

    .user-info-form {
        &.has-no-access {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 6;
            }
            * {
                user-select: none;
            }
            .permission-group--title, .el-form-item__label, .group-form-switch-label, .group-form-input-label, .image-cropper {
                opacity: .6;
            }
        }
    }
}
.permission-tooltip {
    max-width: 292px;
}
</style>
