import { Constants } from "@/core/config/constants";
import { BankPermissionGroup } from "@/store/models/bank/permissions/BankPermissionGroup";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export class BankTeamUserModel {
  bank_name: string;
  country: string;
  email: string;
  first_name: string;
  icon_path: string;
  job_title: string;
  last_name: string;
  score: string;
  followed_countries: string[];
  followed_product_areas: string[];
  active_product_areas: string[];
  bank_countries: any[];
  bank_product_areas: any[];
  role: string;
  permissions: BankPermissionGroup;

  constructor(obj: any) {
    this.bank_name = obj['bank_name'];
    this.country = obj['country'];
    this.email = obj['email'];
    this.first_name = obj['first_name'];
    this.icon_path = obj['icon_path'] || Constants.USER_TMP_LOGO;
    this.job_title = obj['job_title'];
    this.role = obj['is_admin'] ? UserRoleEnum.ADMIN : UserRoleEnum.USER;
    this.last_name = obj['last_name'];
    this.score = obj['score'];
    this.followed_countries = obj['followed_countries'];
    this.followed_product_areas = obj['followed_product_areas'];
    this.active_product_areas = this.convertObjectToArray(obj.product_areas);
    this.bank_countries = obj.bank_countries || [];
    this.bank_product_areas = obj.bank_product_areas || [];
    this.permissions = new BankPermissionGroup(obj.permissions);
  }

  get countriesAndProductAreasData() {
    return {
      allCountries: this.bank_countries,
      allProductAreas: this.bank_product_areas,
      selectedCountries: this.followed_countries,
      selectedProductAreas: this.followed_product_areas,
    }
  }

  get getFullName() {
    return `${this.first_name} ${this.last_name}`
  }

  get getPermissionGroupName() {
    return this.permissions && this.permissions.isGroup ? this.permissions.name : '';
  }

  convertObjectToArray(value: any) {
    const data: any[] = [];
    for(let [key, item] of Object.entries(value)) {
      data.push({
        name: key,
        ...(item as any),
      })
    }
    return data;
  }

  selfUpdate(data) {
    for (let [key, value] of Object.entries(data)) {
      this[key] = value;
      if (!data.icon_path) this.icon_path = Constants.USER_TMP_LOGO;
    }
  }

  updateUserCountriesAndProductAreas(countries: string[], productAreas: string[]) {
    this.bank_countries = countries;
    this.bank_product_areas = productAreas;
  }

}
